export const FETCH_USER = 'FETCH_USER';

export const FETCH_POLLS = 'FETCH_POLLS';

export const SAVE_POLL_RESET = 'SAVE_POLL_RESET';
export const SAVE_POLL_REQUEST = 'SAVE_POLL_REQUEST';
export const SAVE_POLL_SUCCESS = 'SAVE_POLL_SUCCESS';
export const SAVE_POLL_FAILURE = 'SAVE_POLL_FAILURE';

export const DELETE_POLL_RESET = 'DELETE_POLL_RESET';
export const DELETE_POLL_REQUEST = 'DELETE_POLL_REQUEST';
export const DELETE_POLL_SUCCESS = 'DELETE_POLL_SUCCESS';
export const DELETE_POLL_FAILURE = 'DELETE_POLL_FAILURE';

export const VOTE_POLL_RESET = 'VOTE_POLL_RESET';
export const VOTE_POLL_REQUEST = 'VOTE_POLL_REQUEST';
export const VOTE_POLL_SUCCESS = 'VOTE_POLL_SUCCESS';
export const VOTE_POLL_FAILURE = 'VOTE_POLL_FAILURE';
